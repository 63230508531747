import "./App.scss";
import React from "react";
import { useEffect } from "react";  
import Home from "./Pages/Home/Home";
import History from "./Pages/History/History";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="body">    
    <ToastContainer/> 
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/history" element={<History />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
