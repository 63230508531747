import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "react-spinners/PropagateLoader";
import "./History.scss";
import { get } from "http";

export default function History() {
  const [gameData, setGameData] = useState(null as any);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('' as string);
  const [gameType, setGameType] = useState('' as string);
  const [title, setTitle] = useState('' as string);
  const [gameStatus, setGameStatus] = useState('' as string);
  const [lastQuestion, setLastQuestion] = useState(null as any);
  const [lastAnswers, setLastAnswers] = useState(null as any);
  const [finalScore, setFinalScore] = useState<number | null>(null);
  const [winPrice, setWinPrice] = useState<number | null>(null);
  const [top3Players, setTop3Players] = useState<any[] | null>(null);
  const nonBreakingSpace = '\u00A0';

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const getToken = () => {
    let universalUrl = window.location.href;
    let url = new URL(universalUrl);
    const getData = new URLSearchParams(url.search);
    let token = getData.get("token")?.toString();
    let gameType = getData.get("gameid")?.toString();
    
    if (token && gameType) {
      setToken(token);
      setGameType(gameType);
    }
  }

  const fetchData = async () => {
    try {

      let baseUrl;
      if(gameType?.includes("AB-Single"))
      {
        baseUrl = "https://5050singleplayer.catmonocle.com/game/gamedetails/"
      }
      else if(gameType?.includes("50"))
      {
        baseUrl = "https://fiftyfifty.catmonocle.com/game/gamedetails/"
      }
      else
      {
        baseUrl = "https://playwithfriends.catmonocle.com/game/gamedetails/"
      }

    // baseUrl = "https://localhost:44303/game/gamedetails/"
     console.log(baseUrl);  
      
      const response = await fetch(baseUrl + token);
      const data = await response.json();
      console.log(data);  
      
      if (!response.ok) {
        toast.error(data.message, { pauseOnHover: true, });
        setLoading(false);
        return;
      }
      else
      {
        setGameData(data);
        setLoading(false);
      }

    } catch (error) {
      console.error(error);
      toast.error("Error fetching data", { pauseOnHover: true, });
      setLoading(false);
    }
  };

  function DateTimeToLocal(dateTime : any)
  {
    const date = new Date(dateTime);
    return date.toLocaleString();
  }

  useEffect(() => {
    if (gameData != null) {
      if (gameData?.gameTitle.includes("AB-Single")) {
        const stringWithoutFirst10Chars = gameData?.gameTitle.slice(10);
        const gameType = stringWithoutFirst10Chars === "HOL" ? "Hollywood" : stringWithoutFirst10Chars === "MUS" ? "Music" : stringWithoutFirst10Chars;
        setTitle("AB Singleplayer " + gameType + " Quiz")
      }
      else if (gameData?.gameTitle.includes("50")) {
        const stringWithoutFirst10Chars = gameData?.gameTitle.slice(3);
        const gameType = stringWithoutFirst10Chars === "HOL" ? "Hollywood" : stringWithoutFirst10Chars === "MUS" ? "Music" : stringWithoutFirst10Chars;
        setTitle("AB Multiplayer " + gameType + " Quiz")
      }
      else {
        const gameType = gameData?.gameTitle === "HOL" ? "Hollywood" : gameData?.gameTitle === "MUS" ? "Music" : gameData?.gameTitle;
        setTitle("Play with friends  " + gameType.substr(0,3) + " Quiz")
      }     
      console.log(gameData);
      
    }
  

  }, [gameData])


  useEffect(() => {
   if(!loading)
   {
    if (gameData == null) {
    return
    }
    setLastQuestion(JSON.parse(gameData?.lastQuestion))
    setLastAnswers(JSON.parse(gameData?.lastAnswers))

    if(gameData?.gameStatus === 100)
    {
      setGameStatus("Finished")
    }
    else if(gameData?.gameStatus === 101)
    {
      setGameStatus("Cancelled Due To Not Enough Players")
    }
    else if(gameData?.gameStatus === 102)
    {
      setGameStatus("Crashed After Started")
    }

    if(gameData?.gameTitle.includes("PWF"))
    {
      const parsedData = JSON.parse(gameData?.wonPlayerScore?.FinalScore);
     setFinalScore(parsedData.finalScore);
     setWinPrice(parsedData.winPrice);
     setTop3Players(parsedData.top3Players);
    }
   }
  

  }, [loading])
  
  return (
    <div className="container-fluid history-wrapper">
      {loading? 
      <div className="loader">
        <Loader color="#fff"/>
      </div>
      :
      gameData == null ? 
      <Fragment />
      :
      <div className="row game-container">
      <h4 className="title">
        Game history - <strong>{title}</strong>
      </h4>
      <div className="col-md-11">
        <div className="card">
          <div className="col-md-12 history-row">
            <div className="col-md-6">
              <label className="sub-header">Player Details</label>
              <div className="details-row">
                <div className="col-md-6 row">
                  <label className="label-primary">Player Name/Id : </label>
                  <label className="label-secondary">{gameData?.playTechUserId === null ? "-" : gameData?.playTechUserId}</label>
                </div>
                <div className="col-md-6 row">
                  <label className="label-primary">Client Platform : </label>
                  <label className="label-secondary">{gameData?.clientPlatform === null ? "-" : gameData?.clientPlatform}</label>
                </div>
              </div>
              <div className="details-row">
              <div className="col-md-6 row">
                  <label className="label-primary">Player Locale Code : </label>
                  <label className="label-secondary">{gameData?.localeCode === null ? "-" : gameData?.localeCode}</label>
              </div>

              <div className="col-md-6 row">
                  <label className="label-primary">Currency Code : </label>
                  <label className="label-secondary">{gameData?.currencyCode === null ? "-" : gameData?.currencyCode}</label>
                </div>
              </div>
              <div className="details-row">              
                <div className="col-md-6 row">
                  <label className="label-primary">Player SkinId : </label>
                  <label className="label-secondary">{gameData?.skinId === null ? "-" : gameData?.skinId}</label>
                </div>
               
              {gameData?.gameTitle.includes("PWF")?
              <Fragment />
              :
              <div className="col-md-6 row">
              <label className="label-primary">Player Blocked Count : </label>
              <label className="label-secondary">{gameData?.blockedCount === null ? "-" : gameData?.blockedCount}</label>
              </div>
              }
              </div>
            </div>
            <div className="col-md-6">
              <label className="sub-header">PlayTech Transaction Details</label>
              <div className="details-row">
                <div className="col-md-6 row">
                  <label className="label-primary">Wager Transaction Time (UTC): </label>
                  <label className="label-secondary">{gameData?.gameWagerTime === null ? "-" : DateTimeToLocal(gameData?.gameWagerTime)}</label>
                </div>
                <div className="col-md-6 row">
                  <label className="label-primary">Game GameCycle Id : </label>
                  <label className="label-secondary">{gameData?.gameCycleId === null ? "-" : gameData?.gameCycleId}</label>
                </div>
              </div>
              <div className="details-row">
                <div className="col-md-6 row">
                  <label className="label-primary">Transaction Id : </label>
                  <label className="label-secondary">{gameData?.playtechTransId === null ? "-" : gameData?.playtechTransId}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 history-row">
            <div className="col-md-12">
              <label className="sub-header">Game Details</label>
              <div className="details-row">
                <div className="col-md-4 row">
                  <label className="label-primary">Game ID : </label>
                  <label className="label-secondary">{gameData?.gameId === null ? "-" : gameData?.gameId}</label>
                </div>
                <div className="col-md-4 row">
                  <label className="label-primary">Game Status : </label>
                  <label className="label-secondary">{gameStatus}</label>
                </div>
                <div className="col-md-4 row">
                  <label className="label-primary">Game Win : </label>
                  <label className="label-secondary">{gameData?.isWon === null ? "-" : gameData?.isWon ? "Yes" : "No"}</label>
                </div>
              </div>
              <div className="details-row">
              <div className="col-md-4 row">
                  <label className="label-primary">Game Start Time : </label>
                  <label className="label-secondary">{gameData?.gameStartTime === null ? "-" : DateTimeToLocal(gameData?.gameStartTime)}</label>
                </div>
                <div className="col-md-4 row">
                  <label className="label-primary">Game End Time : </label>
                  <label className="label-secondary">{gameData?.gameEndTIme === null ? "-" : DateTimeToLocal(gameData?.gameEndTIme)}</label>
                </div>
                <div className="col-md-4 row">
                  <label className="label-primary">Game Played Rounds : </label>
                  <label className="label-secondary">{gameData?.allAnswers === null ? "-" : gameData?.allAnswers.length}</label>
                </div>
              </div>
              <hr  style={{color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/>
              <div className="details-row">

              {gameData?.gameTitle.includes("50")?
              <div className="col-md-4 row">
              <label className="label-primary">Previous Jackpot : </label>
              <label className="label-secondary">{gameData?.previousJackpot === null ? "-" : gameData?.previousJackpot.toFixed(2)}</label>
              </div> 
              :
              <Fragment />              
              }  

              <div className="col-md-4 row">
                  <label className="label-primary">Jackpot : </label>
                  <label className="label-secondary">{gameData?.jackpot === null ? "-" : gameData?.jackpot.toFixed(2)}</label>
              </div>           
            
               {gameData?.gameTitle.includes("AB-Single")?
                <div className="col-md-4 row">
                <label className="label-primary">Cashout Requested Middle of Game : </label>
                <label className="label-secondary">{gameData?.isReqCashOut === null ? "-" : gameData?.isReqCashOut ? "Yes" : "No"}</label>
                </div>
                :
                <Fragment />
              }
              {gameData?.gameTitle.includes("AB-Single")?
                <div className="col-md-4 row">
                <label className="label-primary">Pass Option used : </label>
                <label className="label-secondary">{gameData?.isPass === null ? "-" : gameData?.isPass ? "Yes" : "No"}</label>
                </div> 
                :
                <Fragment />
              }  
               {gameData?.gameTitle.includes("AB-Single")?
                 <Fragment />
                :
                <div className="col-md-4 row">
                <label className="label-primary">Total Players : </label>
                <label className="label-secondary">{gameData?.totalPlayers === null ? "-" : gameData?.totalPlayers}</label>
                </div> 
              }  
              </div>

              {/* <hr  style={{color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/> */}
              <div className="details-row">
                
                <div className="col-md-4 row">
                  <label className="label-primary">Total Questions : </label>
                  <label className="label-secondary">{gameData?.allAnswers === null ? "-" : gameData?.allAnswers.length}</label>
                </div>
                {gameData?.gameTitle.includes("PWF")?
                <div className="col-md-4 row">
                  <label className="label-primary">Won Player Count : </label>
                  <label className="label-secondary">{gameData?.wonPlayersPrize === null ? "-" : gameData?.wonPlayersPrize.length}</label>
                </div>
                :
                gameData?.gameTitle.includes("50")?
                <div className="col-md-4 row">
                  <label className="label-primary">Won Player Count : </label>
                  <label className="label-secondary">{gameData?.wonPlayersCount === null ? "-" : gameData?.wonPlayersCount}</label>
                </div>
                :
                <Fragment/>
                }
                
               {gameData?.gameTitle.includes("50")?
               <div className="col-md-4 row">
               <label className="label-primary">Player Won Prize : </label>
               <label className="label-secondary">{gameData?.wonJackpot === null ? "-" : gameData?.wonJackpot.toFixed(2)}</label>
               </div>
                :
                <Fragment />
                }            
              </div>

              <hr  style={{color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/>
              <div className="details-row">
                <div className="col-md-6 row">
                  <label className="label-primary">Game Errors Type : </label>
                  <label className="label-secondary">{gameData?.errorType === null ? "-" : gameData?.errorType}</label>
                </div>
                <div className="col-md-6 row">
                  <label className="label-primary">Game Errors Message : </label>
                  <label className="label-secondary">{gameData?.errorMessage === null ? "-" : gameData?.errorMessage}</label>
                </div>
               
              </div>

              <hr  style={{color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/>
              <div className="details-row">          
                <div className="col-md-4 row">
                  <label className="label-primary">Last Question Details: </label>
                  <div>
                    <ul>
                      <li>
                      <label className="label-primary">Last Question : </label>
                      <label className="label-secondary">{lastQuestion?.content === null ? "-" : lastQuestion?.content}</label>
                      </li>
                      <li>
                      <label className="label-primary">Question Number: </label>
                      <label className="label-secondary">{lastQuestion?.questionCursor === null ? "-" : lastQuestion?.questionCursor + 1}</label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 row">
                  <label className="label-primary">Last Question Answers : </label>
                  <div>
                    <ul>
                      <li>
                      <label className="label-secondary">{lastAnswers?.answers[0] === null ? "-" : lastAnswers?.answers[0]}</label>
                      </li>
                      <li>
                      <label className="label-secondary">{lastAnswers?.answers[1] === null ? "-" : lastAnswers?.answers[1]}</label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 row">
                  <label className="label-primary">Last Correct Answer Details: </label>
                  <div>
                    <ul>
                      <li>
                      <label className="label-primary">Correct Answer : </label>
                      <label className="label-secondary">{gameData?.lastCorrectAnswer === null ? "-" : gameData?.lastCorrectAnswer}</label>
                      </li>
                      {
                        gameData?.gameTitle.includes("AB-Single")?
                        <li>
                        <label className="label-primary">Answer Selected Time (Sec) : </label>
                        <label className="label-secondary">{gameData?.answerSelectedSec === null ? "-" : gameData?.answerSelectedSec}</label>
                        </li>
                        :
                        <Fragment />   
                      }
                    </ul>
                  </div>
                </div>          
              </div>
              { gameData?.gameTitle.includes("PWF")?
              <>
              <div className="details-row">   
                    <div className="col-md-4 row">
                      <label className="label-primary">Joker Played Question Number :  </label>
                      <label className="label-secondary">{gameData?.joker === null ? "-" : gameData?.joker}</label>         
                    </div>   
                    <div className="col-md-4 row">
                      <label className="label-primary">Final Score :  </label>
                      <label className="label-secondary">{finalScore === null ? "-" : finalScore}</label>         
                    </div> 
                    <div className="col-md-4 row">
                      <label className="label-primary">Won Price :  </label>
                      <label className="label-secondary">{winPrice === null ? "-" : winPrice}</label>         
                    </div>     
                 </div>  

                  <hr  style={{color: '#000000', backgroundColor: '#000000', height: .5, borderColor : '#000000'}}/>
                  <div className="details-row">   
                  <div className="col-md-6 row">
                      <label className="label-primary">Top 3 Players :</label>
                      <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Score</th>
                            </tr>
                          </thead>
                          <tbody>
                      {top3Players?.map((player : any,index : any)=>{
                       return(
                        <tr key={index}>
                         <td style={{border:"1px solid #343a4054"}}>{player.name}</td>
                         <td style={{border:"1px solid #343a4054"}}>{player.score}</td>
                       </tr>
                        )})}
                       </tbody>
                     </table>       
                    </div>

                    <div className="col-md-6 row">
                      <label className="label-primary">Won Players Prize :</label>
                      <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Won Prize</th>
                              <th>Tire</th>
                            </tr>
                          </thead>
                          <tbody>
                      {gameData?.wonPlayersPrize?.map((player : any,index : any)=>{
                       return(
                        <tr key={index}>
                         <td style={{border:"1px solid #343a4054"}}>{player.playerId}</td>
                         <td style={{border:"1px solid #343a4054"}}>{player.wonPrice}</td>
                         <td style={{border:"1px solid #343a4054"}}>{player.wonTire}</td>
                       </tr>
                        )})}
                       </tbody>
                     </table>       
                    </div>
                  </div> 
              </>
                
                 
                 :
                 <Fragment/>     
                }     
              <div className="details-row">              
                <div className={gameData?.gameTitle.includes("PWF") ? "col-md-6 row" : "col-md-12 row"}>
                <label className="label-primary">All Questions & Answers : </label>
                <div className="row-color">
                  <div className="row">
                    <div className="box red"></div>
                    <label className="label" style={{marginLeft:"8px"}}>Wrong Answer</label>
                  </div>               
                  <div className="row">
                    <div className="box orange"></div>
                    <label className="label"  style={{marginLeft:"8px"}}>Answer Selected By Auto</label>
                  </div>
                  <div className="row">
                    <div className="box green"></div>
                    <label className="label"  style={{marginLeft:"8px"}}>Correct Answer</label>
                  </div>
                </div>
                {gameData?.gameTitle.includes("AB-Single")?
                   <table className="table table-bordered">
                   <thead>
                     <tr>
                       <th>Question No.</th>
                       <th>Player Answer</th>
                       <th>Correct Answer</th>
                       <th>Auto Answer</th>
                     </tr>
                   </thead>
                   <tbody>
                   {gameData?.allAnswers === null ?  <Fragment /> : 
                     gameData?.allAnswers.map((answer : any,index : any)=>{
                       return(
                         <tr key={index} style={{backgroundColor:answer.autoAnswer === "auto" || answer.playerAnswer === null ? "#ffa50070" : answer.playerAnswer !== null && answer.correctAnswer === null ? "#00800070" : answer.playerAnswer !== null && answer.correctAnswer == null ? "#ff000070" : "" }}>
                         <td style={{border:"1px solid #343a4054"}}>{index+1}</td>
                         <td style={{border:"1px solid #343a4054"}}>{answer.playerAnswer === null ? "-" : answer.playerAnswer}</td>
                         <td style={{border:"1px solid #343a4054"}}>{answer.correctAnswer === null ? "-" : answer.correctAnswer}</td>
                         <td style={{border:"1px solid #343a4054"}}>{answer.autoAnswer === null ? "-" : answer.autoAnswer === "auto" ? "Yes" : "-"}</td>
                       </tr>);
                     })
                   }
                   </tbody>
                   </table>    

                   :

                   <table className="table table-bordered">
                   <thead>
                     <tr>
                       <th>Question No.</th>
                       <th>Player Answer</th>
                       <th>Correct Answer</th>
                       <th>Auto Answer</th>                 
                       {gameData?.gameTitle.includes("PWF")?<th>Score</th> : <Fragment/>}
                       <th>Answer Selected Time(Sec)</th>
                     </tr>
                   </thead>
                   <tbody>
                   {gameData?.allAnswers === null ?  <Fragment /> : 
                     gameData?.allAnswers.map((answer : any,index : any)=>{
                       return(
                         <tr key={index} style={{backgroundColor:answer.playerAnswer === "" &&  answer.correctAnswer !== null ? "#ffa50070" : answer.playerAnswer === answer.correctAnswer ? "#00800070" : answer.playerAnswer !== "" && answer.correctAnswer  !== null ? "#ff000070" : "" }}>
                         <td style={{border:"1px solid #343a4054"}}>{index+1}</td>
                         <td style={{border:"1px solid #343a4054"}}>{answer.playerAnswer === null ? "-" : answer.playerAnswer}</td>
                         <td style={{border:"1px solid #343a4054"}}>{answer.correctAnswer === null ? "-" : answer.correctAnswer}</td>
                         <td style={{border:"1px solid #343a4054"}}>{answer.autoAnswer === null ? "-" : gameData?.gameTitle.includes("PWF")? answer.autoAnswer : "-" }</td>
                         {gameData?.gameTitle.includes("PWF")? <td style={{border:"1px solid #343a4054"}}>{answer.autoAnswer === null ? "-" : answer.score }</td> : <Fragment />}
                         <td style={{border:"1px solid #343a4054"}}>{answer.autoAnswer === null ? "-" : answer.time }</td>
                       </tr>);
                     })
                   }
                   </tbody>
                   </table>    
                }            
                </div>
                {gameData?.gameTitle.includes("PWF") ?
                <div className="col-md-6 row">
                   <label className="label-primary" style={{marginBottom:"40px"}}>Last 10 Messages Player Sent : </label>      
                   <table className="table table-bordered">
                   <thead>
                     <tr>
                       <th>Date & Time</th>
                       <th>Message</th>
                       <th>Game Type</th>
                       <th>Lobby or InGame</th>                 
                     </tr>
                   </thead>
                   <tbody>
                      {gameData?.message.map((msg : any , index : any)=>{
                          return(
                            <tr key={index}>
                            <td style={{border:"1px solid #343a4054"}}>{DateTimeToLocal(msg.DateTime)}</td>
                            <td style={{border:"1px solid #343a4054"}}>{msg.Message}</td>
                            <td style={{border:"1px solid #343a4054"}}>{msg.ServerNameType === 0 ? "Hollywood" : msg.ServerNameType === 1 ? "80S" : msg.ServerNameType === 2 ? "90S" : msg.ServerNameType === 3 ? "00S" : "Music" }</td>
                            <td style={{border:"1px solid #343a4054"}}>{msg.ChatLocation === 0 ? "Looby" : "InGame"}</td>
                            </tr>
                          );
                      })}              
                   </tbody>
                   </table>
                </div>
                :
                <Fragment/>
                }           
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </div>
  );
}
